class Reveal {
  constructor(el) {
    this._toggle(el);
  }

  _toggle(el) {
    const content = $(el).find('[data-reveal-content]');

    console.log(el);

    $(el).find('[data-reveal-button]').on('click', function(e) {
      e.preventDefault();
      content.slideToggle();
      $(el).toggleClass('is-active');
      $('html,body').animate({scrollTop: content.offset().top});
    });
  }
}

export default Reveal;
